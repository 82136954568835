import React, { useContext, useEffect, useState } from "react"
import { ColumnsType } from "antd/es/table"
import { Col, Modal, Row, Spin, Tooltip } from "antd"
import io from "socket.io-client"
import CallQueueTable from "./CallQueueTable"
import {
  MinHeightContainer,
  TableHeaderStyle
} from "../voicemail/Voicemails.style"
import { AgentCallersType, CallQueueType, CallersType } from "../voicemail/type"
import { get } from "../../../services/api"
import Button from "../../Button/Button"
import CallQueueLogoutIcon from "../../Icons/CallQueueLogout"
import CallQueueLogInIcon from "../../Icons/CallQueueLogIn"
// import { RootState } from "../../../store/store"
// import { useSelector } from "react-redux"
import CallWrapperContext2 from "../../CallWrapper2/CallWrapperContext"

// var request_call_data = {
//   domain: 'DevTest',
//   filter: '1037',
//   type: 'call',
// };

//   'bearer': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJucyIsImV4cCI6MTcwOTEwNTA3OSwiaWF0IjoxNzA5MDE4Njc5LCJpc3MiOiJzYW5kYm94LWNvcmUyLnRlbHdhcmUubmV0IiwianRpIjoiM2U3OWQwMzk2YTI2ZmZkOTkzOGY4NDRmOTdhZDVkOTViMzY4MWQ5MCIsInN1YiI6IjEwMzdARGV2VGVzdCIsInRpbWVfb3V0Ijo4NjQwMDAwMCwiZG9tYWluIjoiRGV2VGVzdCIsInRlcnJpdG9yeSI6IkRldmVsb3BtZW50IiwidXNlciI6IjEwMzciLCJ1c2VyX2VtYWlsIjoibW9oYW1tZWQuaEBzaW1mb3Jtc29sdXRpb25zLmNvbSIsInVzZXJfc2NvcGUiOiJCYXNpYyBVc2VyIiwiZGlzcGxheU5hbWUiOiJNZCBIYW5pZiBCaSIsIm1hc2tfY2hhaW4iOm51bGwsImRlcGFydG1lbnQiOiJEZXZlbG9wbWVudCIsImxvZ2luIjoiMTAzN0BEZXZUZXN0In0.xcA9YDXgjI8G0DOArRGKSHaM_BPeBw05WZVxQd2Cekc'
const CallQueue = () => {
  const [queue, setQueue] = useState<CallQueueType[]>([])
  const [showCallers, setShowCallers] = useState<boolean>(false)
  const [callers, setCallers] = useState([])
  const [callerMappedAgent, setCallerMappedAgent] = useState({})
  const [agent, setAgent] = useState([])
  const [showAgent, setShowAgent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAgentsLoading, setIsAgentsLoading] = useState<boolean>(false)
  const [isCallerLoading, setIsCallerLoading] = useState<boolean>(false)
  const [callersWaiting, setCallersWaiting] = useState({})
  const [waitTime, setWaitTime] = useState({})
  const {
    callCenterStatus,
    callCenterStatusTrigger,
    incommingCalls,
    forceUpdate
  } = useContext(CallWrapperContext2)
  // let socket
  const initSocket = () => {
    const socket = io(
      `${import.meta.env.VITE_WEPHONE_SOCKET_URL}:${
        import.meta.env.VITE_WEPHONE_SOCKET_PORT
      }`,
      {
        transports: ["websocket"],
        secure: false
      }
    )
    const webphone_credential = JSON.parse(
      localStorage.getItem("webphone-credential")
    )
    const request_call_data = {
      domain: webphone_credential.domain,
      type: "queue",
      bearer: localStorage.getItem("access-token")
      // domain: "DevTest",
      // filter: localStorage.getItem("usercode"),
      // subtype: "queue",
    }

    // const request_presence_data = {
    //   domain: "DevTest",
    //   type: "presence",
    //   bearer: localStorage.getItem("access-token")
    // }
    socket.on("connect", function () {
      console.log("connect call_queue")
      socket.emit("subscribe", request_call_data)
      // socket.emit("subscribe", request_presence_data)
    })

    socket.on("connect_error", function (err) {
      console.log("status call_queue", "connect_error")
      console.log("status call_queue", err)
    })

    socket.on("error", function (err) {
      console.log("status call_queue", "error")
      console.log("status call_queue", err)
    })

    socket.on("status", function (data) {
      console.log("callstatus call_queue", data)
    })

    socket.on("presence", function (data) {
      console.log("presence call_queue", data)
    })

    socket.on("call-queue", function (data) {
      console.log("call_queue", data)
    })
    socket.on("queue", function (data) {
      console.log("queue call_queue", data)
      if (data.count_session || data.count_session == 0) {
        setCallersWaiting((prevState) => {
          let callers = { ...prevState, [data.user]: data.count_session }
          return callers
        })
      }
      // if (data.gau_queue_max_wait_domain) {
      //   setWaitTime(data.gau_queue_max_wait_domain)
      // }
      setWaitTime((prevState) => {
        let waitTime = { ...prevState, [data.user]: data.gau_queue_max_wait }
        return waitTime
      })
    })
  }

  useEffect(() => {
    if (incommingCalls && callCenterStatus === "SingleCall") {
      fetchQueues()
    }
  }, [incommingCalls, forceUpdate])

  const fetchQueues = (initial = false) => {
    get("/api/v1/soft-phone/call-queue/?is_park_queue=false")
      .then((res) => {
        res.data?.data?.forEach((data) => {
          get(
            `/api/v1/soft-phone/call-queue/read_call_agent/?queue_name=${data.queue_name}`
          )
            .then((res) => {
              const agent = res.data?.data?.find(
                (data) => data.extension == localStorage.getItem("usercode")
              )
              setCallerMappedAgent((prevState) => ({
                ...prevState,
                [data.queue_name]: agent?.status
              }))
            })
            .catch((error) => {
              console.warn({ error })
            })
        })

        setQueue(res.data?.data)
        if (initial) {
          let callers = {}
          let waitTime = {}
          res?.data?.data?.map((queueData) => {
            callers = {
              ...callers,
              [queueData?.queue_name]: queueData?.callers_waiting
            }
            waitTime = {
              ...waitTime,
              [queueData?.queue_name]: "0.0"
            }
          })
          setCallersWaiting(callers)
          setWaitTime(waitTime)
        }
      })
      .catch((error) => {
        console.warn({ error })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    initSocket()
    fetchQueues(true)

    // return () => {
    //   socket?.disconnect()
    // }
  }, [])

  useEffect(() => {
    fetchQueues()
  }, [callCenterStatusTrigger])

  const columns: ColumnsType<CallQueueType> = [
    {
      title: (
        <TableHeaderStyle>
          <p>Call Queue</p>
        </TableHeaderStyle>
      ),
      dataIndex: "description",
      key: "description",
      render: (element: CallQueueType["description"]) => {
        return <div>{element}</div>
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <p>Extension</p>
        </TableHeaderStyle>
      ),
      dataIndex: "queue_name",
      key: "queue_name",
      render: (element: CallQueueType["queue_name"]) => {
        return <div className="coloractive">{element}</div>
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <p>Wait Time</p>
        </TableHeaderStyle>
      ),
      dataIndex: "transcript",
      key: "transcript",
      render: (element: CallQueueType["queue_name"], record) => {
        // return <div>{waitTime}</div>
        const totalSeconds = parseFloat(waitTime[record.queue_name])
        const minutes = Math.floor(totalSeconds / 60)
        const seconds = Math.floor(totalSeconds % 60)
        const paddedMinutes = String(minutes).padStart(2, "0")
        const paddedSeconds = String(seconds).padStart(2, "0")

        return <div>{`${paddedMinutes}:${paddedSeconds}`}</div>
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <p>Callers Waiting</p>
        </TableHeaderStyle>
      ),
      dataIndex: "callers_waiting",
      key: "callers_waiting",
      render: (element: CallQueueType["callers_waiting"], record) => {
        return (
          <div
            className="coloractive"
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setIsCallerLoading(true)
              get(
                `/api/v1/soft-phone/call-queue/read_callers_waiting/?queue_name=${record.queue_name}`
              )
                .then((res) => {
                  setCallers(res.data?.data)
                })
                .finally(() => {
                  setIsCallerLoading(false)
                })
              setShowCallers(true)
            }}
          >
            <Tooltip title="Show Callers" placement="bottomLeft">
              {callersWaiting[record.queue_name]}
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <p>Agents</p>
        </TableHeaderStyle>
      ),
      dataIndex: "agents_count",
      key: "agents_count",
      render: (element: CallQueueType["agents_count"], record) => {
        return (
          <div
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setIsAgentsLoading(true)
              get(
                `/api/v1/soft-phone/call-queue/read_call_agent/?queue_name=${record.queue_name}`
              )
                .then((res) => {
                  setAgent(res.data?.data)
                })
                .finally(() => {
                  setIsAgentsLoading(false)
                })
              setShowAgent(true)
            }}
          >
            {element}
          </div>
        )
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <div> Action</div>
        </TableHeaderStyle>
      ),
      dataIndex: "Action",
      key: "Actiong",
      render: (_, record) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (callerMappedAgent[record.queue_name] == "available") {
                get(
                  `/api/v1/soft-phone/call-queue/logout_agent/?queue_name=${record.queue_name}`
                ).then(() => {
                  fetchQueues()
                })
              } else {
                get(
                  `/api/v1/soft-phone/call-queue/login_agent/?queue_name=${record.queue_name}`
                ).then(() => {
                  fetchQueues()
                })
              }
            }}
          >
            {callerMappedAgent[record.queue_name] == "available" ? (
              <Tooltip title="Log In" placement="bottomLeft">
                <div>
                  <CallQueueLogoutIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Log Out" placement="bottomLeft">
                <div>
                  <CallQueueLogInIcon />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  const callersColumns: ColumnsType<CallersType> = [
    {
      title: (
        <TableHeaderStyle>
          <p>Caller</p>
        </TableHeaderStyle>
      ),
      dataIndex: "from_user",
      key: "from_user",
      render: (element: CallersType["from_user"]) => {
        return <div>{element}</div>
      }
    },
    // {
    //   title: (
    //     <TableHeaderStyle>
    //       <p>Name</p>
    //     </TableHeaderStyle>
    //   ),
    //   dataIndex: "from_name",
    //   key: "from_name",
    //   render: (element: CallersType["from_name"]) => {
    //     return <div>{element}</div>
    //   }
    // },
    {
      title: (
        <TableHeaderStyle>
          <p>Duration</p>
        </TableHeaderStyle>
      ),
      dataIndex: "time_queued",
      key: "time_queued",
      render: (element: CallersType["time_queued"]) => {
        const timezoneOffset = new Date(element).getTimezoneOffset() * 60 * 1000
        const timeDifference =
          Date.now() - new Date(element).getTime() + timezoneOffset
        const minutes = Math.floor(timeDifference / 60000) // 1 minute = 60,000 milliseconds
        const seconds = ((timeDifference % 60000) / 1000).toFixed(0) // Remainder in milliseconds converted to seconds
        const paddedMinutes = minutes.toString().padStart(2, "0")
        const paddedSeconds = seconds.toString().padStart(2, "0")
        return (
          <div>
            {paddedMinutes}:{paddedSeconds}
          </div>
        )
      }
    }
  ]

  const agentCallersColumns: ColumnsType<AgentCallersType> = [
    {
      title: (
        <TableHeaderStyle>
          <p>Name</p>
        </TableHeaderStyle>
      ),
      dataIndex: "full_name",
      key: "full_name",
      render: (element: AgentCallersType["full_name"]) => {
        return <div>{element}</div>
      }
    },
    {
      title: (
        <TableHeaderStyle>
          <p>Extension</p>
        </TableHeaderStyle>
      ),
      dataIndex: "extension",
      key: "extension",
      render: (element: AgentCallersType["extension"]) => {
        return <div>{element}</div>
      }
    }
  ]

  return (
    <div>
      {isLoading ? (
        <MinHeightContainer>
          <Spin />
        </MinHeightContainer>
      ) : (
        <CallQueueTable
          data={queue}
          columns={columns}
          heading="My Queues"
          // total={totalVoicemails}
          // handlePageChange={handlePageChange}
          // currentPage={currentPage}
        />
      )}

      <Modal
        centered
        closeIcon={false}
        open={showCallers}
        // wrapClassName="logoutmodal"
        footer={[
          <Row>
            <Col xs={24} lg={24}>
              <div
                className="footerright"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => {
                    setShowCallers(false)
                  }}
                  type="primary"
                  size="small"
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        ]}
      >
        <div>Callers in sales</div>
        {isCallerLoading ? (
          <MinHeightContainer>
            <Spin />
          </MinHeightContainer>
        ) : (
          <CallQueueTable
            data={callers}
            columns={callersColumns}
            heading=""
            // total={totalVoicemails}
            // handlePageChange={handlePageChange}
            // currentPage={currentPage}
          />
        )}
      </Modal>

      <Modal
        centered
        closeIcon={false}
        open={showAgent}
        wrapClassName="logoutmodal"
        footer={[
          <Row>
            <Col xs={24} lg={24}>
              <div
                className="footerright"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => {
                    setShowAgent(false)
                  }}
                  type="primary"
                  size="small"
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        ]}
      >
        <div>Agent in sales</div>
        {isAgentsLoading ? (
          <MinHeightContainer>
            <Spin />
          </MinHeightContainer>
        ) : (
          <CallQueueTable
            data={agent}
            columns={agentCallersColumns}
            heading=""
            // total={totalVoicemails}
            // handlePageChange={handlePageChange}
            // currentPage={currentPage}
          />
        )}
      </Modal>
    </div>
  )
}

export default CallQueue
