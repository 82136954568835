import { InputProps, FormItemProps } from "antd"
import { InputStyle, FormStyle } from "./InputField.style"

interface Inputprops extends InputProps {
  label?: string
  hint?: string
  validationStatus?: FormItemProps["validateStatus"]
  valuePropName?: string
  errorMessage?: string
  textareaHeight?: string
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>
  ) => void
}
type Props = Inputprops & FormItemProps
function About(props: Props) {
  const {
    addonBefore,
    placeholder,
    type,
    size,
    prefix,
    suffix,
    status,
    label,
    name,
    validationStatus,
    hint,
    disabled,
    valuePropName,
    onChange,
    value,
    required,
    maxLength,
    minLength,
    defaultValue,
    autoFocus,
    errorMessage,
    textareaHeight,
    onPressEnter
  } = props

  let input = (
    <InputStyle
      addonBefore={addonBefore}
      placeholder={placeholder}
      type={type}
      size={size}
      prefix={prefix}
      suffix={suffix}
      status={status}
      disabled={disabled}
      onChange={onChange}
      value={value}
      maxLength={maxLength}
      minLength={minLength}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onPressEnter={onPressEnter}
    />
  )
  if (type === "password") {
    input = (
      <InputStyle.Password
        placeholder={placeholder}
        onChange={onChange}
        status={status}
        maxLength={maxLength}
        minLength={minLength}
      />
    )
  }
  if (type === "textarea") {
    input = (
      <InputStyle.TextArea
        style={{ height: textareaHeight || 120 }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        maxLength={maxLength}
      />
    )
  }
  return (
    <FormStyle
      className="success"
      validateStatus={validationStatus}
      help={hint}
      label={label}
      name={name}
      labelAlign="left"
      rules={[
        {
          required,
          min: minLength || 0,
          message: errorMessage
        }
      ]}
      valuePropName={valuePropName}
    >
      {input}
    </FormStyle>
  )
}
export default About
