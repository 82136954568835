/* eslint-disable no-useless-escape */
import { PhoneNumber, parsePhoneNumber } from "libphonenumber-js"

const formatPhoneNumber = (phone: string) => {
  let number: PhoneNumber
  let code: string
  let phoneNum: string
  let phoneNumber = phone.replace(/[()-\s]/g, "")
  const specialChars = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~\s]/

  if (phoneNumber.length < 20 && !specialChars.test(phoneNumber)) {
    if (phoneNumber.length === 10) {
      phoneNumber = `+1${phoneNumber}`
    }
    if (phoneNumber.length > 10 && !phoneNumber.includes("+")) {
      phoneNumber = `+${phoneNumber}`
    }
    if (phoneNumber.includes("+")) {
      number = parsePhoneNumber(phoneNumber)
      const [countryCode, ...num] = number.formatInternational().split(" ")
      code = countryCode
      phoneNum = num.join().replace(",", "")
    } else {
      code = ""
      phoneNum = phoneNumber.replace(/[^\d]/g, "").replace(",", "")
    }
    const len = phoneNum.length
    const getPhoneNum = () => {
      if (len < 8) {
        return phoneNum
      }
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(
        3,
        6
      )}-${phoneNum.slice(6, len)}`.replace(",", "")
    }
    return {
      code,
      phoneNum: getPhoneNum().replace(",", "")
    }
  }

  return {
    code: "+1",
    phoneNum: phoneNumber.includes("+") ? phoneNumber.slice(2) : phoneNumber
  }
}

export const formatCodePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return
  const { code, phoneNum } = formatPhoneNumber(phoneNumber)
  if (code) return `${code} ${phoneNum}`
  else return phoneNum
}

export default formatPhoneNumber
